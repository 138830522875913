import axios from "axios";
import constants from "../utils/constants"
import { store } from '../store'
import { logout } from "../store/reducers/login";

const request = axios.create({
  baseURL: constants.apiUrl,
});

request.interceptors.request.use(function (config) {
  const token = store.getState().login.authJwt;
  if (token)
    config.headers.Authorization = `Bearer ${token}`;

  return config;
});

request.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  store.dispatch(logout())
  return error;
 } else {
  return Promise.reject(error);
 }
});


export default request

