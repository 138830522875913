import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "react-router-redux";
import { ProjectMapper } from "../../services/mappers/projectMapper";
import { SharedProjectMapper } from "../../services/mappers/sharedProjectMapper";
import {
  getAllMeProjects,
  getAllMeSharedProjects,
  GetAllMeSharedProjects,
  GetAllMeProjects,
  CreateMeDefaultProject,
  createMeDefaultProject,
} from "../../services/project";
import {
  setAllProjects,
  getAllSharedProjects,
  setAllSharedProjects,
  getAllProjects,
  setErrorSharedProjectMessage,
  setErrorProjectMessage,
  createDefaultProject,
  loaderChangeSharedProject,
  loaderChangeProject,
  loaderCreateProject,
} from "../reducers/project";

function* getAllSharedProjectsByUserIdRequest() {
  try {
    yield put(loaderChangeSharedProject(true));
    const data: GetAllMeSharedProjects = yield call(getAllMeSharedProjects);
    const mapped = new SharedProjectMapper().fromApiList(data);
    yield put(setAllSharedProjects(mapped));
    yield put(setErrorSharedProjectMessage(""));
  } catch (err) {
    console.error(err);
    yield put(setErrorProjectMessage("RETRY_AGAIN"));
  } finally {
    yield put(loaderChangeSharedProject(false));
  }
}

function* getAllProjectsByUserIdRequest() {
  try {
    yield put(loaderChangeProject(true));
    const data: GetAllMeProjects = yield call(getAllMeProjects);
    const mapped = new ProjectMapper().fromApiList(data);
    yield put(setAllProjects(mapped));
    yield put(setErrorProjectMessage(""));
  } catch (err) {
    console.error(err);
    yield put(setErrorProjectMessage("RETRY_AGAIN"));
  } finally {
    yield put(loaderChangeProject(false));
  }
}

function* createNewProjectRequest(payload: PayloadAction<{ title: string }>) {
  try {
    yield put(loaderCreateProject(true));
    const data: CreateMeDefaultProject = yield call(
      createMeDefaultProject,
      payload.payload
    );
    yield put(setErrorSharedProjectMessage(""));
    yield put(push(`/editor/${data.id}`));
  } catch (err: any) {
    console.error(err?.response);
    yield put(setErrorSharedProjectMessage("RETRY_AGAIN"));
  } finally {
    yield put(loaderCreateProject(false));
  }
}

export function* projectMain() {
  yield all([
    takeEvery(getAllSharedProjects.type, getAllSharedProjectsByUserIdRequest),
    takeEvery(getAllProjects.type, getAllProjectsByUserIdRequest),
    takeEvery(createDefaultProject.type, createNewProjectRequest),
  ]);
}
