import { all, fork } from "redux-saga/effects";
import { editorMain } from "./editor";
import { galleryMain } from "./gallery";
import { loginMain } from "./login";
import { projectMain } from "./project";
import { registerMain } from "./register";

export const rootSaga = function* root() {
  yield all([
    fork(loginMain),
    fork(registerMain),
    fork(projectMain),
    fork(editorMain),
    fork(galleryMain),
  ]);
};
