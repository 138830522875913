import { SharedProject } from "../../entities/sharedProject";
import { Mapper } from "./mapper";
import { ProjectMapper } from "./projectMapper";

export class SharedProjectMapper extends Mapper<SharedProject> {
  projectMapper: ProjectMapper = new ProjectMapper();

  fromApi(data: any): SharedProject {
    return {
      id: data.id,
      project: this.projectMapper.fromApi(data.project),
      user: data.user
    };
  }

  toApi(data: SharedProject) {
    return {
      id: data.id,
      project: data.project,
      user: data.user
    };
  }
}
