import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  loaderChange,
  loginRequest,
  LoginRequestPayload,
  setAuth,
  setErrorMessage,
  setUser,
  logout,
  confirmRequest,
  ConfirmRequestPayload,
} from "../reducers/login";
import { login, confirmToken, RegisterResponse } from "../../services/user";
import { push } from "connected-react-router";

function* startLogin(loginPayload: PayloadAction<LoginRequestPayload>) {
  try {
    yield put(loaderChange(true));
    const data: RegisterResponse = yield call(login, loginPayload.payload);
    yield put(setAuth(data.jwt));
    yield put(setUser(data.user));
    yield put(setErrorMessage(""));
    yield put(push("/home"));
  } catch (err) {
    yield put(setErrorMessage("USER_NOT_FOUND"));
  } finally {
    yield put(loaderChange(false));
  }
}

function* handleLogout() {
  try {
    yield put(setAuth(""));
    yield put(setUser(null));
    yield put(setErrorMessage(""));
    yield put(push("/"));
  } catch (err) {
    yield put(setErrorMessage("USER_NOT_FOUND"));
  } finally {
    yield put(loaderChange(false));
  }
}

function* handleConfirm(confirmPayload: PayloadAction<ConfirmRequestPayload>) {
  try {
    yield call(confirmToken, confirmPayload.payload);
    confirmPayload.payload.onSuccess();
  } catch (err) {
    confirmPayload.payload.onError();
  }
}

export function* loginMain() {
  yield all([
    takeEvery(loginRequest.type, startLogin),
    takeEvery(logout.type, handleLogout),
    takeEvery(confirmRequest.type, handleConfirm),
  ]);
}
