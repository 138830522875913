import { Plan } from "../entities/plan";
import { FullUser, User } from "../entities/user";
import request from "./request";

export type RegisterParams = {
  username: string;
  email: string;
  password: string;
  phone: string;
};

export type RegisterResponse = {
  user: User;
  jwt: string;
};

export async function register(
  params: RegisterParams
): Promise<RegisterResponse> {
  const { data } = await request.post<RegisterResponse>(
    "/auth/local/register",
    {
      username: params.username,
      email: params.email,
      password: params.password,
      phone: params.phone,
    }
  );

  return data;
}

export type LoginParams = {
  email: string;
  password: string;
};

export type LoginResponse = {
  jwt: string;
};

export async function login(params: LoginParams): Promise<LoginResponse> {
  const { data } = await request.post<LoginResponse>("/auth/local", {
    identifier: params.email,
    password: params.password,
  });

  return data;
}

export type LoginConfirmTokenParams = {
  token: string;
};

export async function confirmToken(
  params: LoginConfirmTokenParams
): Promise<LoginResponse> {
  const { data } = await request.post<LoginResponse>("/user/confirm", {
    token: params.token,
  });

  return data;
}

export type UserChangePassword = {
  currentPassword: string;
  newPassword: string;
};

export async function changePassword(
  params: UserChangePassword
): Promise<LoginResponse> {
  const { data } = await request.put<LoginResponse>("/user/password", {
    currentPassword: params.currentPassword,
    newPassword: params.newPassword,
  });

  return data;
}

export type UsageResponse = {
  usage: {
    uploaded: number;
    watched: number;
  };
  plans: {
    maxUpload: number;
    maxWatched: number;
  };
};

export async function getCurrentUsage(): Promise<UsageResponse> {
  const { data } = await request.get<UsageResponse>("/user/usage");
  return data;
}

export async function getMe(): Promise<FullUser> {
  const { data } = await request.get<FullUser>("/user/me");
  return data;
}

export async function getCurrentPlan(): Promise<Plan> {
  const { data } = await request.get<Plan>("/user/plan");
  return data;
}

export async function forgotPassword(phone: string): Promise<any> {
  const { data } = await request.post<any>("/user/forgot", {
    phone,
  });

  return data;
}
