import { all, call, put, select, takeEvery } from "@redux-saga/core/effects";
import { MediaNode } from "../../entities/mediaNode";
import { GetAllMedia, getAllMeMedia } from "../../services/media";
import { RootReducer } from "../reducers";
import { mediaListAction } from "../reducers/gallery";

function* getAllMediaFilesRequest() {
  try {
    const mediaNodes: MediaNode[] = yield select(
      (state: RootReducer) => state.gallery.media?.source
    );
    yield put(mediaListAction.loading(true));
    const data: GetAllMedia = yield call(getAllMeMedia);
    const finalMediaNodes = data.reverse().map((item) => {
      const found = mediaNodes.find((mediaNode) => mediaNode.id === item.id);
      if (found) {
        return {
          ...item,
          selected: found.selected,
        };
      }

      return item;
    });
    yield put(mediaListAction.source(finalMediaNodes));
    yield put(mediaListAction.errorMessage(""));
  } catch (err) {
    yield put(mediaListAction.errorMessage("Something wrong occured, please try again."));
  } finally {
    yield put(mediaListAction.loading(false));
  }
}

export function* galleryMain() {
  yield all([takeEvery(mediaListAction.request.type, getAllMediaFilesRequest)]);
}
