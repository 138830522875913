import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    (window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
  compose;

const persistConfig = {
  key: "root-application",
  storage,
  whitelist: ["login"],
};

export const history = createBrowserHistory();
export const persistentReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);
export const store = createStore(
  persistentReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
