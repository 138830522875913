export abstract class Mapper<T> {
  abstract fromApi(data: any): T;
  abstract toApi(data: T): any;

  fromApiList(data: any[]): T[] {
    return data.map(this.fromApi.bind(this));
  }

  toApiList(data: T[]): any[] {
    return data.map(this.toApi.bind(this));
  }
}
