import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../entities/user";
import { LoginConfirmTokenParams, LoginParams } from "../../services/user";
import { withPayloadType } from "../utils";

// Actions
export interface LoginRequestPayload extends LoginParams {}
export const loginRequest = createAction(
  "login/loginRequest",
  withPayloadType<LoginRequestPayload>()
);
export const loaderChange = createAction(
  "login/loaderChange",
  withPayloadType<boolean>()
);
export const setErrorMessage = createAction(
  "login/setErrorMessage",
  withPayloadType<string>()
);

export interface ConfirmRequestPayload extends LoginConfirmTokenParams {
  onSuccess: () => void;
  onError: () => void;
}

export const confirmRequest = createAction(
  "login/confirm",
  withPayloadType<ConfirmRequestPayload>()
);
export const setAuth = createAction("login/setAuth", withPayloadType<string>());
export const setUser = createAction(
  "login/setUser",
  withPayloadType<User | null>()
);
export const logout = createAction("login/logout");

// Reducer
export type LoginState = {
  loading: boolean;
  errorMessage: string;
  authJwt: string;
  user: User | null;
};

const INITIAL_STATE: LoginState = {
  loading: false,
  errorMessage: "",
  authJwt: "",
  user: null,
};

export default createReducer(INITIAL_STATE, {
  [loaderChange.type]: (state, action: PayloadAction<boolean>) => {
    return { ...state, loading: action.payload };
  },
  [setErrorMessage.type]: (state, action: PayloadAction<string>) => {
    return { ...state, errorMessage: action.payload };
  },
  [setAuth.type]: (state, action: PayloadAction<string>) => {
    return { ...state, authJwt: action.payload };
  },
  [setUser.type]: (state, action: PayloadAction<User>) => {
    return { ...state, user: action.payload };
  },
});
