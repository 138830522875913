import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import i18n from "i18next";
import { withPayloadType } from "../utils";

// Actions
export const changeLanguage = createAction(
  "app/changeLanguage",
  withPayloadType<string>()
);

export const changeTour = createAction(
  "app/changeTour",
  withPayloadType<boolean>()
);

export const setTourSteps = createAction(
  "app/setTourSteps",
  withPayloadType<any[]>()
);

// Reducer
export type AppState = {
  language: string;
  tour: boolean;
  tourSteps: any[];
};

const INITIAL_STATE: AppState = {
  language: "pt",
  tour: false,
  tourSteps: [],
};

export default createReducer(INITIAL_STATE, {
  [changeLanguage.type]: (state, action: PayloadAction<string>) => {
    i18n.changeLanguage(action.payload);
    return { ...state, language: action.payload };
  },
  [changeTour.type]: (state, action: PayloadAction<boolean>) => {
    return { ...state, tour: action.payload };
  },
  [setTourSteps.type]: (state, action: PayloadAction<any[]>) => {
    return { ...state, tourSteps: action.payload };
  },
});
