import { Project } from "../../entities/project";
import { Mapper } from "./mapper";
import { MediaNodeProjectMapper } from "./mediaNodeProjectMapper";

export class ProjectMapper extends Mapper<Project> {
  mediaNodeProjectMapper: MediaNodeProjectMapper = new MediaNodeProjectMapper();

  fromApi(data: any): Project {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      owner: data.owner,
      thumbnail: data.thumbnail,
      status: data.status,
      mediaNodeProjects: this.mediaNodeProjectMapper.fromApiList(
        data.media_node_projects
      ),
    };
  }

  toApi(data: Project) {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      owner: data.owner,
      thumbnail: data.thumbnail,
      status: data.status,
      media_node_projects: this.mediaNodeProjectMapper.toApiList(
        data.mediaNodeProjects
      ),
    };
  }
}
