export function isDevelopment(): boolean {
  if (!process.env.REACT_APP_APP_ENV) return false;
  return !["prod", "production"].includes(process.env.REACT_APP_APP_ENV.trim());
}

export function bytesToSizeString(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
