import { Redirect, Route, Switch } from "react-router-dom";
import { history } from "./store";
import { ConnectedRouter } from "connected-react-router";
import { RootReducer } from "./store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { lazy, Suspense, useEffect } from "react";
import { changeLanguage } from "./store/reducers/app";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import "./assets/resizable.scss";
import "./assets/carbon.scss";
import { ToastContainer } from "react-toastify";
import LogRocket from "logrocket";

const LoginPage = lazy(() => import("./pages/login"));
const HomePage = lazy(() => import("./pages/home"));
const EditorPage = lazy(() => import("./pages/editor"));
const SettingPage = lazy(() => import("./pages/setting"));
const IframePage = lazy(() => import("./pages/iframe"));
const PreviewPage = lazy(() => import("./pages/preview"));
const PlansPage = lazy(() => import("./pages/plans"));

function App() {
  const { language } = useSelector((store: RootReducer) => store.app);
  const { user } = useSelector((store: RootReducer) => store.login);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLanguage(language));
    if (user?.id)
      LogRocket.identify(user?.id, {
        name: user?.username,
        email: user?.email,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <ToastContainer />

          <Suspense
            fallback={
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                Loading...
              </div>
            }
          >
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/home" component={HomePage} />
              <Route exact path="/editor/:projectId" component={EditorPage} />
              <Route exact path="/plans" component={PlansPage} />
              <Route exact path="/setting" component={SettingPage} />
              <Route exact path="/iframe/:projectId" component={IframePage} />
              <Route exact path="/preview/:projectId" component={PreviewPage} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </ConnectedRouter>
    </div>
  );
}

export default App;
