import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { withPayloadType } from "../utils";
import { RegisterParams } from "../../services/user";

// Actions
export interface RegisterRequestPayload extends RegisterParams {
  onSuccess: () => void;
}

export const registerRequest = createAction(
  "register/registerRequest",
  withPayloadType<RegisterRequestPayload>()
);
export const loaderChange = createAction(
  "register/loaderChange",
  withPayloadType<boolean>()
);
export const setErrorMessage = createAction(
  "register/setErrorMessage",
  withPayloadType<string>()
);

// Reducer
export type RegisterState = {
  loading: boolean;
  errorMessage: string;
};

const INITIAL_STATE: RegisterState = {
  loading: false,
  errorMessage: "",
};

export default createReducer(INITIAL_STATE, {
  [loaderChange.type]: (state, action: PayloadAction<boolean>) => {
    return { ...state, loading: action.payload };
  },
  [setErrorMessage.type]: (state, action: PayloadAction<string>) => {
    return { ...state, errorMessage: action.payload };
  },
});
