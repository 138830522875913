import { combineReducers } from "redux";
import login, { LoginState } from "./login";
import { connectRouter } from "connected-react-router";
import app, { AppState } from "./app";
import register, { RegisterState } from "./register";
import project, { ProjectState } from "./project";
import editor, { EditorState } from "./editor";
import gallery, { GalleryState } from "./gallery";

export interface RootReducer {
  login: LoginState;
  app: AppState;
  register: RegisterState;
  project: ProjectState;
  editor: EditorState;
  gallery: GalleryState;
  router: any;
}

export const createRootReducer = (history: any) =>
  combineReducers<RootReducer>({
    login,
    app,
    project,
    register,
    editor,
    gallery,
    router: connectRouter(history),
  });
