import {
  ActionCreatorWithPreparedPayload,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";

export function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export type ReffilableContentType<T> = {
  loading: boolean;
  source: T;
  errorMessage: string;
};

export type ReffilableContentObjectAction<T = any> = {
  request: ActionCreatorWithPreparedPayload<
    [t: string],
    string,
    string,
    never,
    never
  >;
  loading: ActionCreatorWithPreparedPayload<
    [t: boolean],
    boolean,
    string,
    never,
    never
  >;
  source: ActionCreatorWithPreparedPayload<[t: T], T, string, never, never>;
  errorMessage: ActionCreatorWithPreparedPayload<
    [t: string],
    string,
    string,
    never,
    never
  >;
};

export function refillableContentActions<T>(params: {
  key: string;
}): ReffilableContentObjectAction<T> {
  return {
    request: createAction(`${params.key}/request`),
    loading: createAction(`${params.key}/loader`, withPayloadType<boolean>()),
    source: createAction(`${params.key}/source`, withPayloadType<T>()),
    errorMessage: createAction(
      `${params.key}/error-message`,
      withPayloadType<string>()
    ),
  };
}

export function refillableContentReducer<T>(
  key: string,
  reffilableAction: ReffilableContentObjectAction<T>
) {
  return {
    [reffilableAction.loading.type]: (
      state: any,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: action.payload,
        },
      };
    },
    [reffilableAction.errorMessage.type]: (
      state: any,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: action.payload,
        },
      };
    },
    [reffilableAction.source.type]: (state: any, action: PayloadAction<T>) => {
      return {
        ...state,
        [key]: {
          ...state[key],
          source: action.payload,
        },
      };
    },
  };
}
