import { MediaNode } from "../entities/mediaNode";
import { MediaNodeProject } from "../entities/mediaNodeProject";
import { MediaNodeProjectMapper } from "./mappers/mediaNodeProjectMapper";
import request from "./request";

export type GetAllMedia = MediaNode[];
export async function getAllMeMedia(): Promise<GetAllMedia> {
  const { data } = await request.get<GetAllMedia>("/media-nodes/me");
  return data;
}

export type RemoveMediaNode = MediaNode;
export async function removeMediaNode(
  mediaNodeId: string
): Promise<RemoveMediaNode> {
  const { data } = await request.delete<RemoveMediaNode>(
    `/media-nodes/${mediaNodeId}`
  );

  return data;
}

export type GetAllMediaProject = MediaNodeProject[];
export async function getAllMediaProjects(
  projectId: string
): Promise<GetAllMediaProject> {
  const dataMapper = new MediaNodeProjectMapper();
  const { data } = await request.get<GetAllMediaProject>(
    "/media-node-projects",
    {
      params: {
        "project.id": projectId,
      },
    }
  );

  return dataMapper.fromApiList(data);
}

export type EditMediaNodeProject = MediaNodeProject;
export async function editMediaNodeProject(
  mediaNodeProjectId: string,
  name: string,
  first: boolean
): Promise<EditMediaNodeProject> {
  const dataMapper = new MediaNodeProjectMapper();
  const { data } = await request.put<EditMediaNodeProject>(
    `/media-node-projects/me/${mediaNodeProjectId}`,
    {
      name,
      default: first,
    }
  );

  return dataMapper.fromApi(data);
}

export type UnbindMediaNodeProject = MediaNodeProject;
export async function unbindMediaNodeProject(
  mediaNodeProjectId: string
): Promise<UnbindMediaNodeProject> {
  const dataMapper = new MediaNodeProjectMapper();
  const { data } = await request.delete<UnbindMediaNodeProject>(
    `/media-node-projects/me/${mediaNodeProjectId}`
  );

  return dataMapper.fromApi(data);
}

export async function createMediaNodeSecondsWatched(
  mediaNodeId: string,
  ownerId: string,
  secondsWatched: number
): Promise<void> {
  const { data } = await request.post<void>(`/media-node-seconds-downloadeds`, {
    durationInSeconds: secondsWatched || 0,
    createdAt: new Date().toISOString(),
    owner: ownerId,
    media_node: mediaNodeId,
  });

  return data;
}
