import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { select } from "redux-saga/effects";
import { MediaNodeProject } from "../../entities/mediaNodeProject";
import { Project } from "../../entities/project";
import { ProjectMapper } from "../../services/mappers/projectMapper";
import { GetAllMediaProject, getAllMediaProjects } from "../../services/media";
import {
  GetOneProject,
  getOneProject,
  saveProject,
} from "../../services/project";
import { RootReducer } from "../reducers";
import {
  mediaNodeProjectAction,
  projectEditorAction,
  saveProjectEditorRequest,
  SaveProjectEditorRequestParams,
} from "../reducers/editor";
import { push } from "react-router-redux";

function* getOneProjectRequest(getOnePayload: PayloadAction<string>) {
  try {
    yield put(projectEditorAction.source(null));
    yield put(projectEditorAction.loading(true));
    const mapperProject = new ProjectMapper();
    const data: GetOneProject = yield call(
      getOneProject,
      getOnePayload.payload
    );

    yield put(projectEditorAction.source(mapperProject.fromApi(data)));
    yield put(projectEditorAction.errorMessage(""));
  } catch (err) {
    yield put(push(`/home`));
    yield put(projectEditorAction.errorMessage("Cannot load project"));
  } finally {
    yield put(projectEditorAction.loading(false));
  }
}

function* loadAlMediaNodeProjectsByProjectId(params: PayloadAction<string>) {
  try {
    yield put(mediaNodeProjectAction.source(null));
    yield put(mediaNodeProjectAction.loading(true));
    const data: GetAllMediaProject = yield call(
      getAllMediaProjects,
      params.payload
    );
    yield put(mediaNodeProjectAction.source(data));
    yield put(mediaNodeProjectAction.errorMessage(""));
  } catch (err) {
    yield put(
      mediaNodeProjectAction.errorMessage("Cannot load media projects")
    );
  } finally {
    yield put(mediaNodeProjectAction.loading(false));
  }
}

function* saveProjectRequest(
  params: PayloadAction<SaveProjectEditorRequestParams>
) {
  try {
    const currentProject: Project = yield select(
      (state: RootReducer) => state.editor.project.source
    );
    const mediaNodeProjects: MediaNodeProject[] = yield select(
      (state: RootReducer) => state.editor.mediaNodeProject.source
    );

    const mapperProject = new ProjectMapper();
    const mappedProjectResult: any = mapperProject.toApi({
      ...currentProject,
      mediaNodeProjects,
    });

    yield call(saveProject, mappedProjectResult);

    params.payload.onSuccess();
  } catch (err) {
    params.payload.onError();
  }
}

export function* editorMain() {
  yield all([
    takeEvery(projectEditorAction.request.type, getOneProjectRequest),
    takeEvery(
      mediaNodeProjectAction.request.type,
      loadAlMediaNodeProjectsByProjectId
    ),
    takeEvery(saveProjectEditorRequest.type, saveProjectRequest),
  ]);
}
