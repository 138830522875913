import { MediaNodeProject } from "../../entities/mediaNodeProject";
import { Mapper } from "./mapper";

export class MediaNodeProjectMapper extends Mapper<MediaNodeProject> {
  fromApi(data: any): MediaNodeProject {
    return {
      id: data.id,
      name: data.name,
      mediaNode: data.media_node,
      project: data.project,
      flowPosition: data.flow_position,
      elements: data.elements || [],
      first: data.default,
    };
  }

  toApi(data: MediaNodeProject) {
    return {
      id: data.id,
      name: data.name,
      media_node: data.mediaNode,
      project: data.project,
      flow_position: data.flowPosition,
      elements: data.elements || [],
      first: data.first,
    };
  }
}
