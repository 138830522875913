import { StringableActionCreator } from "@redux-saga/types";
import { Project } from "../entities/project";
import { ConsolidatedProjectMetric } from "../entities/projectMetric";
import { SharedProject } from "../entities/sharedProject";
import request from "./request";

export type GetAllMeSharedProjects = [];
export async function getAllMeSharedProjects(): Promise<GetAllMeSharedProjects> {
  const { data } = await request.get<GetAllMeSharedProjects>(
    "/shared-projects/me"
  );
  return data;
}

export type GetAllSharedProjectUser = SharedProject;
export async function getAllSharedProjectUsers(
  projectId: string
): Promise<GetAllSharedProjectUser[]> {
  const { data } = await request.get<GetAllSharedProjectUser[]>(
    `/projects/${projectId}/shared-project-users`
  );
  return data;
}

export type DeleteSharedProject = SharedProject;
export async function deleteSharedProject(
  sharedProjectId: string
): Promise<DeleteSharedProject[]> {
  const { data } = await request.delete<GetAllSharedProjectUser[]>(
    `/projects/share/${sharedProjectId}`
  );
  return data;
}

export type ShareProjectEmail = {};
export async function shareProjectWithEmail(email: string, projectId: string) {
  const { data } = await request.post<ShareProjectEmail>("/projects/share", {
    email,
    projectId,
  });

  return data;
}

export type GetAllMeProjects = [];
export async function getAllMeProjects(): Promise<GetAllMeProjects> {
  const { data } = await request.get<GetAllMeProjects>("/projects/me");
  return data;
}

export type CreateMeDefaultProject = { id: number };
export async function createMeDefaultProject({
  title,
}: {
  title: string;
}): Promise<CreateMeDefaultProject> {
  const { data } = await request.post<CreateMeDefaultProject>("/projects/me", {
    title,
  });
  return data;
}

export type GetOneProject = Project;
export async function getOneProject(id: string): Promise<GetOneProject> {
  const { data } = await request.get<GetOneProject>(`/projects/me/${id}`);
  return data;
}

export type GetProductionProject = Project;
export async function getProductionProject(id: string): Promise<GetOneProject> {
  const { data } = await request.get<GetOneProject>(`/projects/production/${id}`);
  return data;
}

export type GetPreviewProject = Project;
export async function getPreviewProject(id: string): Promise<GetOneProject> {
  const { data } = await request.get<GetOneProject>(`/projects/preview/${id}`);
  return data;
}

export type DeleteProject = Project;
export async function deleteProject(id: string): Promise<DeleteProject> {
  const { data } = await request.delete<DeleteProject>(`/projects/me/${id}`);
  return data;
}

export type SaveProject = Project[];
export async function saveProject(
  project: Partial<Project>
): Promise<SaveProject> {
  const { data } = await request.put<SaveProject>(
    `/projects/me/${project.id}`,
    project
  );
  return data;
}

export type GetConsolidatedMetrics = ConsolidatedProjectMetric;
export async function getConsolidatedMetrics(
  projectId: string
): Promise<ConsolidatedProjectMetric> {
  const { data } = await request.get<ConsolidatedProjectMetric>(
    `/projects/metrics/${projectId}`
  );

  return data;
}
