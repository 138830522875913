import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { MediaNode } from "../../entities/mediaNode";
import {
  ReffilableContentType,
  refillableContentActions,
  refillableContentReducer,
  withPayloadType,
} from "../utils";

// Actions
export const mediaListAction = refillableContentActions<MediaNode[] | null>({
  key: "media-list",
});

export const toggleSelectMediaNode = createAction(
  "gallery/select-media-node",
  withPayloadType<string>()
);

export const deselectAllMediaNode = createAction(
  "gallery/deselect-media-node",
);

// Reducer
export type GalleryState = {
  media: ReffilableContentType<MediaNode[] | null>;
};

const INITIAL_STATE: GalleryState = {
  media: {
    errorMessage: "",
    loading: false,
    source: [],
  },
};

export default createReducer(INITIAL_STATE, {
  ...refillableContentReducer<MediaNode[] | null>("media", mediaListAction),
  [toggleSelectMediaNode.type]: (state, action: PayloadAction<string>) => {
    return {
      ...state,
      media: {
        ...state.media,
        source:
          state.media.source?.map((item) => ({
            ...item,
            selected:
              item.id && item.id.toString() === action.payload
                ? !item.selected
                : item.selected,
          })) ?? [],
      },
    };
  },
  [deselectAllMediaNode.type]: (state, action: PayloadAction<string>) => {
    return {
      ...state,
      media: {
        ...state.media,
        source:
          state.media.source?.map((item) => ({
            ...item,
            selected: false,
          })) ?? [],
      },
    };
  },
});
