import loginEN from "./en/login.json";
import loginES from "./es/login.json";
import loginPT from "./pt/login.json";

import commonEN from "./en/common.json";
import commonES from "./es/common.json";
import commonPT from "./pt/common.json";

import homeEN from "./en/home.json";
import homeES from "./en/home.json";
import homePT from "./pt/home.json";

import httpEN from "./en/http.json";
import httpPT from "./pt/http.json";
import httpES from "./es/http.json";

import settingEN from "./en/setting.json";
import settingPT from "./pt/setting.json";
import settingES from "./es/setting.json";

import editorEN from "./en/editor.json";
import editorPT from "./pt/editor.json";
import editorES from "./es/editor.json";

import galleryEN from "./en/gallery.json";
import galleryPT from "./pt/gallery.json";
import galleryES from "./es/gallery.json";

const resources = {
  en: {
    login: loginEN,
    common: commonEN,
    home: homeEN,
    http: httpEN,
    setting: settingEN,
    editor: editorEN,
    gallery: galleryEN,
  },
  es: {
    login: loginES,
    common: commonES,
    home: homeES,
    http: httpES,
    setting: settingES,
    editor: editorES,
    gallery: galleryPT,
  },
  pt: {
    login: loginPT,
    common: commonPT,
    home: homePT,
    http: httpPT,
    setting: settingPT,
    editor: editorPT,
    gallery: galleryES,
  },
};

export default resources;
