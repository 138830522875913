import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "../../entities/project";
import { SharedProject } from "../../entities/sharedProject";
import { withPayloadType } from "../utils";

// Actions
export const getAllProjects = createAction("register/getAllProjects");
export const getAllSharedProjects = createAction(
  "register/getAllSharedProjects"
);

export const setAllSharedProjects = createAction(
  "register/setAllSharedProjects",
  withPayloadType<SharedProject[]>()
);

export const setAllProjects = createAction(
  "register/setAllProjects",
  withPayloadType<Project[]>()
);

export const createDefaultProject = createAction(
  "register/createDefaultProject",
  withPayloadType<{ title: string }>()
);

export const loaderChangeProject = createAction(
  "register/loaderChangeProject",
  withPayloadType<boolean>()
);

export const loaderChangeSharedProject = createAction(
  "register/loaderChangeShared",
  withPayloadType<boolean>()
);

export const loaderCreateProject = createAction(
  "register/loaderCreateProject",
  withPayloadType<boolean>()
);

export const setErrorProjectMessage = createAction(
  "register/setErrorProjectMessage",
  withPayloadType<string>()
);

export const setErrorSharedProjectMessage = createAction(
  "register/setErrorSharedProjectMessage",
  withPayloadType<string>()
);

// Reducer
export type ProjectState = {
  sharedProject: {
    list: SharedProject[];
    loading: boolean;
    errorMessage: string;
  };
  project: {
    list: Project[];
    loading: boolean;
    errorMessage: string;
  };
  createProject: {
    loading: boolean;
  };
};

const INITIAL_STATE: ProjectState = {
  sharedProject: {
    list: [],
    loading: false,
    errorMessage: "",
  },
  project: {
    list: [],
    loading: false,
    errorMessage: "",
  },
  createProject: {
    loading: false,
  },
};

export default createReducer(INITIAL_STATE, {
  [loaderChangeProject.type]: (state, action: PayloadAction<boolean>) => ({
    ...state,
    project: {
      ...state.project,
      loading: action.payload,
    },
  }),
  [loaderChangeSharedProject.type]: (
    state,
    action: PayloadAction<boolean>
  ) => ({
    ...state,
    sharedProject: {
      ...state.sharedProject,
      loading: action.payload,
    },
  }),
  [loaderCreateProject.type]: (state, action: PayloadAction<boolean>) => ({
    ...state,
    createProject: {
      ...state.createProject,
      loading: action.payload,
    },
  }),
  [setErrorProjectMessage.type]: (state, action: PayloadAction<string>) => {
    return {
      ...state,
      project: {
        ...state.project,
        errorMessage: action.payload,
      },
    };
  },
  [setErrorSharedProjectMessage.type]: (
    state,
    action: PayloadAction<string>
  ) => {
    return {
      ...state,
      sharedProject: {
        ...state.sharedProject,
        errorMessage: action.payload,
      },
    };
  },
  [setAllProjects.type]: (state, action: PayloadAction<Project[]>) => {
    return {
      ...state,
      project: {
        ...state.project,
        list: action.payload,
      },
    };
  },
  [setAllSharedProjects.type]: (
    state,
    action: PayloadAction<SharedProject[]>
  ) => {
    return {
      ...state,
      sharedProject: {
        ...state.sharedProject,
        list: action.payload,
      },
    };
  },
});
