import { call, put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { register, RegisterResponse } from "../../services/user";
import {
  RegisterRequestPayload,
  loaderChange,
  setErrorMessage,
  registerRequest,
} from "../reducers/register";

function* registerUser(registerPayload: PayloadAction<RegisterRequestPayload>) {
  try {
    yield put(loaderChange(true));
    const data: Generator<RegisterResponse> = yield call(
      register,
      registerPayload.payload
    );
    // TODO: need done here too
    console.log(data);
    yield put(setErrorMessage(""));
    registerPayload.payload.onSuccess();
  } catch (err) {
    // TODO: Need done
    console.log(err);
  } finally {
    yield put(loaderChange(false));
  }
}

export function* registerMain() {
  yield takeEvery(registerRequest.type, registerUser);
}
