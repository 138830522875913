export type Theme = {};

export const theme: Theme = {
  borderColor: "#dcdcdc",
  colors: {
    black: "#161616",
  },
  editor: {
    inspectorGroup: "#212326",
    inspectorSubGroup: "#303030",

    mainBg: "#323539",
    mainTextColor: "white",
    mainTextHovered: "black",

    tabBg: "#3F4449",
    tabIcon: "white",
    tabIconHovered: "#606060",

    timelineBg: "#212326",
    timelineBorder: "#2F3235",

    borderSelectedItem: "#f1c40f",
  },
};
