import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ElementUI, ElementPosition } from "../../entities/element";
import {
  MediaNodeFlowPosition,
  MediaNodeProject,
} from "../../entities/mediaNodeProject";
import { Project } from "../../entities/project";
import { Tabs } from "../../pages/editor/components/editorTabBar/tabs";
import {
  ReffilableContentType,
  refillableContentActions,
  refillableContentReducer,
  withPayloadType,
} from "../utils";
import { v4 as uuidv4 } from "uuid";

// Actions
export const projectEditorAction = refillableContentActions<Project | null>({
  key: "project",
});

export const mediaNodeProjectAction = refillableContentActions<
  MediaNodeProject[] | null
>({
  key: "mediaNodeProject",
});

export type SaveProjectEditorRequestParams = {
  onSuccess: () => void;
  onError: () => void;
};
export const saveProjectEditorRequest = createAction(
  "editor/save-project-editor-request",
  withPayloadType<SaveProjectEditorRequestParams>()
);

export const setCurrentMediaNodeProject = createAction(
  "editor/set-current-media-node-project",
  withPayloadType<string>()
);

export const updateCurrentMediaNodeProjectElements = createAction(
  "editor/update-current-media-node-project-element",
  withPayloadType<ElementUI[]>()
);

export type UpdateElementsOrderParams = {
  order: number;
  elementId: string;
};
export const updateElementsOrder = createAction(
  "editor/update-elements-order",
  withPayloadType<UpdateElementsOrderParams[]>()
);

export type UpdateElementDragResizeParams = {
  id: string;
  position: Partial<ElementPosition>;
};
export const updateElementDragResize = createAction(
  "editor/update-element-drag-resize",
  withPayloadType<UpdateElementDragResizeParams>()
);

export type UpdateElementParams = Partial<ElementUI>;
export const updateElementParams = createAction(
  "editor/update-element-params",
  withPayloadType<UpdateElementParams>()
);

export type DeleteElementParams = Partial<ElementUI>;
export const deleteElement = createAction(
  "editor/delete-element",
  withPayloadType<DeleteElementParams>()
);

export type CloneElementParams = Partial<ElementUI>;
export const cloneElement = createAction(
  "editor/clone-element",
  withPayloadType<CloneElementParams>()
);

export type UpdateCurrentPlayerParams = {
  player: any | null;
  paused: boolean;
  canPlay: boolean;
  currentTime: number;
  muted: boolean;
  totalTime: number;
  height: number;
  width: number;
};
export const updateCurrentPlayer = createAction(
  "editor/update-current-player-params",
  withPayloadType<Partial<UpdateCurrentPlayerParams>>()
);

export const changeCurrentTab = createAction(
  "editor/change-current-tab",
  withPayloadType<Tabs>()
);

export const setGalleryUpload = createAction(
  "editor/set-gallery-upload",
  withPayloadType<boolean>()
);

export const toggleFlowView = createAction("editor/toggle-flow-view");

export type UpdateProjectInfoParams = Partial<
  Pick<Project, "title" | "description">
>;

export const updateProjectInfo = createAction(
  "editor/update-project-info",
  withPayloadType<UpdateProjectInfoParams>()
);

export type UpdateMediaFlowPositionParams = MediaNodeFlowPosition & {
  mediaNodeProjectId: string;
};
export const updateMediaFlowPosition = createAction(
  "editor/update-media-flow-position",
  withPayloadType<UpdateMediaFlowPositionParams>()
);

export const setCurrentElementUI = createAction(
  "editor/set-current-element-ui",
  withPayloadType<string | null>()
);

export const resetAll = createAction("editor/reset-all");

// Action
export type EditorState = {
  project: ReffilableContentType<Project | null>;
  mediaNodeProject: ReffilableContentType<MediaNodeProject[] | null>;
  currentMediaNodeProject: string | null;
  flowViewOpened: boolean;
  galleryUploadOpen: boolean;
  currentPlayer: {
    player: any | null;
    paused: boolean;
    canPlay: boolean;
    currentTime: number;
    muted: boolean;
    totalTime: number;
    width: number;
    height: number;
  };
  currentTab: Tabs;
  currentElementInspector: string | null;
};

// Reducer
const INITIAL_STATE: EditorState = {
  currentPlayer: {
    player: null,
    paused: false,
    canPlay: false,
    currentTime: 0,
    totalTime: 0,
    muted: false,
    height: 0,
    width: 0
  },
  galleryUploadOpen: false,
  flowViewOpened: true,
  currentMediaNodeProject: null,
  currentTab: Tabs.MEDIA_NODE_PROJECT,
  currentElementInspector: null,
  project: {
    errorMessage: "",
    loading: false,
    source: null,
  },
  mediaNodeProject: {
    errorMessage: "",
    loading: false,
    source: [],
  },
};

export default createReducer(INITIAL_STATE, {
  ...refillableContentReducer<Project | null>("project", projectEditorAction),
  ...refillableContentReducer<MediaNodeProject[] | null>(
    "mediaNodeProject",
    mediaNodeProjectAction
  ),
  [updateCurrentPlayer.type]: (
    state,
    action: PayloadAction<UpdateCurrentPlayerParams>
  ) => {
    return {
      ...state,
      currentPlayer: {
        ...state.currentPlayer,
        ...action.payload,
      },
    };
  },
  [toggleFlowView.type]: (state) => {
    return {
      ...state,
      flowViewOpened: !state.flowViewOpened,
    };
  },
  [setGalleryUpload.type]: (state, action: PayloadAction<boolean>) => {
    return {
      ...state,
      galleryUploadOpen: action.payload,
    };
  },
  [updateProjectInfo.type]: (
    state,
    action: PayloadAction<UpdateProjectInfoParams>
  ) => {
    const source: Project | null = state.project.source
      ? {
          ...state.project.source,
          ...action.payload,
        }
      : null;

    return {
      ...state,
      project: {
        ...state.project,
        source,
      },
    };
  },
  [setCurrentElementUI.type]: (state, action: PayloadAction<string | null>) => {
    if (!state.currentMediaNodeProject) return { ...state };

    return {
      ...state,
      currentElementInspector: action.payload ? action.payload : null,
      currentTab: action.payload ? Tabs.INSPECTOR : Tabs.MEDIA_NODE_PROJECT,
    };
  },
  [resetAll.type]: () => {
    return JSON.parse(JSON.stringify(INITIAL_STATE));
  },
  [changeCurrentTab.type]: (state, action: PayloadAction<Tabs>) => {
    return {
      ...state,
      currentTab: action.payload,
    };
  },
  [updateElementsOrder.type]: (
    state: EditorState,
    action: PayloadAction<UpdateElementsOrderParams[]>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          return {
            ...item,
            elements: item.elements.map((element) => {
              const found = action.payload.find(
                (orderItem) =>
                  String(orderItem.elementId) === String(element.id)
              );

              if (found) {
                return {
                  ...element,
                  layerOrder: found.order,
                };
              }
              return element;
            }),
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  [deleteElement.type]: (
    state: EditorState,
    action: PayloadAction<DeleteElementParams>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          return {
            ...item,
            elements: item.elements.filter(
              (element) => String(element.id) !== String(action.payload.id)
            ),
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  // TODO: I didnt finish yet, would be VERY cool
  [cloneElement.type]: (
    state: EditorState,
    action: PayloadAction<CloneElementParams>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    let found: ElementUI | null = null;
    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          const elementFound: ElementUI | undefined = item.elements.find(
            (el) => String(el.id) === String(action.payload.id)
          );

          if (elementFound) {
            found = {
              ...elementFound,
              id: uuidv4(),
            };
          }
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: found ? [...(updatedMediaNodeProjects || []), found] : [],
      },
    };
  },
  [updateMediaFlowPosition.type]: (
    state: EditorState,
    action: PayloadAction<UpdateMediaFlowPositionParams>
  ) => {
    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(action.payload.mediaNodeProjectId) === String(item.id)) {
          return {
            ...item,
            flowPosition: {
              x: action.payload.x,
              y: action.payload.y,
            },
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  [updateElementDragResize.type]: (
    state: EditorState,
    action: PayloadAction<UpdateElementDragResizeParams>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          return {
            ...item,
            elements: item.elements.map((element) => {
              if (String(element.id) === String(action.payload.id)) {
                return {
                  ...element,
                  position: {
                    ...element.position,
                    ...action.payload.position,
                  },
                };
              }
              return element;
            }),
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  [updateElementParams.type]: (
    state: EditorState,
    action: PayloadAction<UpdateElementParams>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          return {
            ...item,
            elements: item.elements.map((element) => {
              if (String(element.id) === String(action.payload.id)) {
                return JSON.parse(
                  JSON.stringify({
                    ...element,
                    ...action.payload,
                  })
                );
              }
              return element;
            }),
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  [updateCurrentMediaNodeProjectElements.type]: (
    state,
    action: PayloadAction<ElementUI[]>
  ) => {
    if (!state.currentMediaNodeProject) return { ...state };

    const updatedMediaNodeProjects = state.mediaNodeProject.source?.map(
      (item) => {
        if (String(state.currentMediaNodeProject) === String(item.id)) {
          return {
            ...item,
            elements: action.payload.map((element, index) => {
              return {
                ...element,
                layerOrder: index,
              };
            }),
          };
        }

        return item;
      }
    );

    return {
      ...state,
      mediaNodeProject: {
        ...state.mediaNodeProject,
        source: updatedMediaNodeProjects || [],
      },
    };
  },
  [setCurrentMediaNodeProject.type]: (state, action: PayloadAction<string>) => {
    const needSelect = action.payload !== state.currentMediaNodeProject;

    return {
      ...state,
      currentMediaNodeProject: needSelect ? action.payload || null : null,
    };
  },
});
