type Constants = {
  validation: {
    email: RegExp,
  },
  apiUrl?: string,
}

const constants: Constants = {
  validation: {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  apiUrl: process.env.REACT_APP_API_URL,
};

export default constants;
